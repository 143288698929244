<template>
  <b-form-group :label="lable" :label-for="lable_for" :state="state">
    <validation-provider
      #default="{ errors }"
      :name="validation_name"
      :rules="rules"
    >
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : ''"
      >
        <b-input-group-prepend is-text>
          <feather-icon :icon="icon" />
        </b-input-group-prepend>
        <b-form-input
          :id="id"
          type="text"
          :state="errors.length > 0 ? false : null"
          :placeholder="placeholder"
          v-model="vmodel"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      state: "",
      required,
      vmodel: "",
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BFormGroup,
    BInputGroup,
    ValidationProvider,
    BFormInput,
    BInputGroupPrepend,
  },
  props: ["validation_name", "lable", "lable_for", "rules", "icon", "id", "placeholder"],
  watch: {
    vmodel(val) {
      this.$emit("vModelSet", val);
    },
  },
  methods: {},
};
</script>