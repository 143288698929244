<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <h3 class="mb-2">DAPP informations</h3>
              <b-row>
                <b-col md="6">
                  <text-field
                    :lable="'Submitter Name'"
                    :lable_for="'iv-submitter-name'"
                    :validation_name="'Submitter Name'"
                    :rules="'required'"
                    :icon="'CpuIcon'"
                    :placeholder="'Expl: John'"
                    @vModelSet="dapp_detail.submitter_name = $event"
                  />
                </b-col>
                <b-col md="6">
                  <text-field
                    :lable="'Email'"
                    :lable_for="'iv-email'"
                    :validation_name="'Email'"
                    :rules="'required|email'"
                    :icon="'PackageIcon'"
                    :placeholder="'Expl: john@example.com'"
                    @vModelSet="dapp_detail.submitter_email = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <text-field
                    :lable="'Dapp Name'"
                    :lable_for="'iv-dapp-name'"
                    :validation_name="'Dapp Name'"
                    :rules="'required'"
                    :icon="'CpuIcon'"
                    :placeholder="'Expl: RugScanner'"
                    @vModelSet="dapp_detail.dapp_name = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <text-field
                    :lable="' Short Description '"
                    :lable_for="'iv-dapp-short-description'"
                    :validation_name="' Short Description'"
                    :rules="'required'"
                    :icon="'CpuIcon'"
                    :placeholder="'Expl: This is a'"
                    @vModelSet="dapp_detail.dapp_short_description = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <text-field
                    :lable="'Website'"
                    :lable_for="'iv-Website'"
                    :validation_name="' Website'"
                    :rules="''"
                    :icon="'LinkIcon'"
                    :placeholder="'Expl: https://test.net/'"
                    @vModelSet="dapp_detail.dapp_web_link = $event"
                  />
                </b-col>
                <b-col md="6">
                  <text-field
                    :lable="'Logo Link'"
                    :lable_for="'iv-Logo-Link'"
                    :validation_name="'Logo Link'"
                    :rules="''"
                    :icon="'ImageIcon'"
                    :placeholder="'Expl: https://www.test.com/wp-content/test/2021/06/New-logo-1-1.png'"
                    @vModelSet="dapp_detail.dapp_logo = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label=" Description"
                    label-for="iv-dapp-description"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=" Description"
                      rules=""
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <div id="app">
                          <vue-editor
                            id="iv-token-description"
                            v-model="dapp_detail.dapp_long_description"
                            :state="errors.length > 0 ? false : null"
                            rows="2"
                            placeholder="Expl: Dapp is an innovative payment network and a new kind of money"
                          ></vue-editor>
                        </div>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label=" How it works"
                    label-for="iv-dapp-how-it-works"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="How it works"
                      rules=""
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <div id="app">
                          <vue-editor
                            id="iv-dapp-how-it-works"
                            v-model="dapp_detail.dapp_how_it_works"
                            :state="errors.length > 0 ? false : null"
                            rows="5"
                            placeholder="Expl: Dapp is an innovative payment network and a new kind of money"
                          ></vue-editor>
                        </div>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <h3 class="mb-2">DAPP Contracts</h3>
              <b-row>
                <b-col md="12">
                  <text-field
                    :lable="'DAPP contract address'"
                    :lable_for="'iv-dapp-address'"
                    :validation_name="'DAPP contract address'"
                    :rules="'required'"
                    :icon="'PackageIcon'"
                    :placeholder="'Expl: 0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2'"
                    @vModelSet="dapp_detail.dapp_contract_address = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <span>Status</span>
                  <b-form-select
                    v-model="dapp_detail.dapp_status"
                    :options="status_options"
                  ></b-form-select>
                </b-col>
                <b-col md="6">
                  <span>Network</span>
                  <b-form-select
                    v-model="dapp_detail.dapp_network"
                    :options="network_options"
                    :state="dapp_detail.dapp_network === null ? false : true"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col md="6">
                  <span>Category</span>
                  <b-form-select
                    v-model="dapp_detail.dapp_category"
                    :options="category_options"
                    :state="dapp_detail.dapp_category === null ? false : true"
                  ></b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Released Date"
                    label-for="iv-released-date"
                    :state="state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Released Date"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      >
                        <b-form-datepicker
                          id="iv-Released-date"
                          v-model="dapp_detail.dapp_release_date"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <text-field
                    :lable="'Bitwiki Link'"
                    :lable_for="'iv-bitwiki_link'"
                    :validation_name="'Bitwiki Link'"
                    :rules="''"
                    :icon="'PackageIcon'"
                    :placeholder="'Expl: beta'"
                    @vModelSet="dapp_detail.dapp_bitwiki_link = $event"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <h3 class="mb-2 mt-2">Social</h3>
                </b-col>

                <b-col md="6">
                  <text-field
                    :lable="'Facebook'"
                    :lable_for="'iv-Facebook'"
                    :validation_name="'Facebook'"
                    :rules="''"
                    :icon="'fbIcon'"
                    :placeholder="'Expl: https:facebook.com/rugscanner'"
                    @vModelSet="dapp_detail.dapp_social_links.facebook = $event"
                  />
                </b-col>
                <b-col md="6">
                  <text-field
                    :lable="'Telegram'"
                    :lable_for="'iv-Telegram'"
                    :validation_name="'Telegram'"
                    :rules="''"
                    :icon="'MessageSquareIcon'"
                    :placeholder="'Expl: https://t.me/bitcoin'"
                    @vModelSet="dapp_detail.dapp_social_links.telegram = $event"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <text-field
                    :lable="'Twitter'"
                    :lable_for="'iv-Twitter'"
                    :validation_name="'Twitter'"
                    :rules="''"
                    :icon="'TwitterIcon'"
                    :placeholder="'Expl: https://twitter.com/bitcoin'"
                    @vModelSet="dapp_detail.dapp_social_links.telegram = $event"
                  />
                </b-col>
                <b-col md="6">
                  <text-field
                    :lable="'Reddit'"
                    :lable_for="'iv-reddit'"
                    :validation_name="'Reddit'"
                    :rules="''"
                    :icon="'BellIcon'"
                    :placeholder="'Expl: https://reddit.com/bitcoin'"
                    @vModelSet="dapp_detail.dapp_social_links.reddit = $event"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <text-field
                    :lable="'Discord'"
                    :lable_for="'iv-discord'"
                    :validation_name="'Discord'"
                    :rules="''"
                    :icon="'PhoneCallIcon'"
                    :placeholder="'Expl: https://discord.com/bitcoin'"
                    @vModelSet="dapp_detail.dapp_social_links.discord = $event"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-col v-if="loading" cols="12" class="text-center">
            <b-spinner class="mt-2" variant="primary" />
          </b-col>
          <b-col v-else cols="12" class="text-center">
            <b-button
              class="mt-2"
              variant="primary"
              type="submit"
              loading="true"
              @click.prevent="validationForm"
            >
              Submit Request
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, url, email } from "@validations";
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BForm,
  BInputGroup,
  BCard,
  BFormDatepicker,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import API from "./../services/api";
import { VueEditor } from "vue2-editor";
import TextField from "./../layouts/components/textField.vue";

export default {
  components: {
    BFormSelect,
    TextField,
    VueEditor,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BSpinner,
  },
  computed: {
    tokenNameValidation() {
      return this.tokenName.length >= 4;
    },
    tokenSymbolValidation() {
      return this.tokenSymbol.length >= 2;
    },
    tokenDescriptionlValidation() {
      return this.tokenDescription.length >= 50;
    },
  },
  data() {
    return {
      status_options: [
        { value: null, text: "Select Status", disabled: true },
        { value: "live", text: "Live" },
        { value: "beta", text: "Beta" },
        { value: "pre-sale", text: "Pre-sale" },
        { value: "development", text: "Development" },
        { value: "inactive", text: "inactive" },
      ],
      network_options: [
        { value: null, text: "Select Network", disabled: true },
        { value: "bsc", text: "BSC" },
        { value: "eth", text: "ETH" },
        { value: "other", text: "Other" },
      ],
      category_options: [
        { value: null, text: "Select Category", disabled: true },
        { value: "game", text: "Game" },
        { value: "marketplace", text: "Marketplace" },
        { value: "gambling", text: "Gambling" },
        { value: "other", text: "Other" },
      ],
      dapp_detail: {
        submitter_name: "",
        submitter_email: "",
        dapp_contract_address: "",
        dapp_web_link: "",
        dapp_logo: "",
        dapp_name: "",
        dapp_short_description: "",
        dapp_status: null,
        dapp_network: null,
        dapp_category: null,
        dapp_release_date: "",
        dapp_bitwiki_link: "",
        dapp_long_description: "",
        dapp_how_it_works: "",
        dapp_social_links: {
          facebook: "",
          twitter: "",
          reddit: "",
          telegram: "",
          discord: "",
        },
      },
      loading: false,
      state: "",
      required,
      url,
      email,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.submitFormData();
        }
      });
    },

    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      });
    },

    submitFormData() {
      API.post("dapp", this.dapp_detail)
        .then((response) => {
          if (response.status == 201) {
            //make the success toast message and rest the data fields
            this.makeToast(
              "success",
              "Congratulations 🏆",
              "Your Dapp has been submitted"
            );
          }
          this.$router.push("/");
          //reset the form
          this.resetFormData();
        })
        .catch((error) => {
          //check for validation errors
          if (error.response.status == 422) {
            let errorList = error.response.data.errors || [];
            const keys = Object.keys(errorList);
            if (keys.length > 0) {
              keys.forEach((key) => {
                this.makeToast("danger", errorList[key][0], "Validation Error");
              });
            }
          } 
           else {
            this.makeToast(
              "danger",
              "Something went wrong d",
              "Please Try Again"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetFormData() {
      this.$refs.simpleRules.reset();
    },
  },
  directives: {
    Ripple,
  },
};
</script>
<style>
.custom-switch .custom-control-label::after {
  top: calc(0.225rem + 10px);
  width: 1rem;
  height: 1rem;
  margin-left: 2.7rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border-color 0s;
}
.custom-switch .custom-control-label::before {
  height: 2.7rem;
  width: 5.7rem;
  transition: opacity 0.25s ease, background-color 0.1s ease;
  user-select: none;
}
.switch-span {
  margin-top: 10px;
}
.quillWrapper .ql-snow.ql-toolbar {
  border-radius: 10px 10px 0px 0px;
}
.ql-editor {
  border-radius: 10px 10px 0px 0px;
}
</style>
